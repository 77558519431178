import type {
    ICompany,
    ILevel,
    INumberPurchaseRequest,
    INumberCancellationRequest,
} from '@/definitions';

export enum REQUEST_TYPE {
    NUMBER_PURCHASE = 'NUMBER_PURCHASE',
    NUMBER_CANCELLATION = 'NUMBER_CANCELLATION',
}

export enum REQUEST_STATUS {
    PENDING = 'PENDING',
    CANCELED = 'CANCELED',
    FINISHED = 'FINISHED',
}

export interface IRequest {
    id: number;
    levelId: number;
    type: REQUEST_TYPE;
    status: REQUEST_STATUS;
    createdBy: number;
    updatedBy?: number;
    createdAt: Date;
    updatedAt: Date;

    // Associations
    level?: ILevel;
    company?: ICompany;
    purchaseRequest?: INumberPurchaseRequest;
    cancellationRequest?: INumberCancellationRequest;
}
