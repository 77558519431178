/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    INumberState,
    NumberMutations,
    NumberActions,
    NumberGetters,
    INumberFilter,
    ISortMenuItem,
    IFilterItem,
} from '@/definitions';

const getDefaultState = (): INumberState => ({
    search: null,
    sort: [],
    page: 1,
    filter: {
        isActive: null,
        company: null,
        level: null,
        type: null,
        state: null,
        outboundOperator: null,
        calltrackingOperator: null,
    },
});

const state: INumberState = getDefaultState();

const mutations: MutationTree<INumberState> = {
    [NumberMutations.SET_SEARCH](state, search: string) {
        state.search = search;
    },
    [NumberMutations.SET_SORT](state, sort: ISortMenuItem[]) {
        state.sort = sort;
    },
    [NumberMutations.SET_PAGE](state, page: number) {
        state.page = page;
    },
    [NumberMutations.SET_FILTER](state, filter: INumberFilter) {
        state.filter.isActive = filter.isActive;
        state.filter.company = filter.company;
        state.filter.level = filter.level;
        state.filter.type = filter.type;
        state.filter.state = filter.state;
        state.filter.outboundOperator = filter.outboundOperator;
        state.filter.calltrackingOperator = filter.calltrackingOperator;
    },

    [NumberMutations.CLEAR_FILTER](state, field?: string) {
        switch (field) {
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            case 'company':
                state.filter.company = getDefaultState().filter.company;
                break;
            case 'level':
                state.filter.level = getDefaultState().filter.level;
                break;
            case 'type':
                state.filter.type = getDefaultState().filter.type;
                break;
            case 'state':
                state.filter.state = getDefaultState().filter.state;
                break;
            case 'outboundOperator':
                state.filter.outboundOperator = getDefaultState().filter.outboundOperator;
                break;
            case 'calltrackingOperator':
                state.filter.calltrackingOperator = getDefaultState().filter.calltrackingOperator;
                break;
            default:
                state.filter.isActive = getDefaultState().filter.isActive;
                state.filter.company = getDefaultState().filter.company;
                state.filter.level = getDefaultState().filter.level;
                state.filter.type = getDefaultState().filter.type;
                state.filter.state = getDefaultState().filter.state;
                state.filter.outboundOperator = getDefaultState().filter.outboundOperator;
                state.filter.calltrackingOperator = getDefaultState().filter.calltrackingOperator;
        }
    },
};

const actions: ActionTree<INumberState, INumberState> = {
    [NumberActions.SET_SEARCH]({ commit }, search: string) {
        commit(NumberMutations.SET_SEARCH, search);
    },
    [NumberActions.SET_SORT]({ commit }, sort: ISortMenuItem[]) {
        commit(NumberMutations.SET_SORT, sort);
    },
    [NumberActions.SET_PAGE]({ commit }, page: number) {
        commit(NumberMutations.SET_PAGE, page);
    },
    [NumberActions.SET_FILTER]({ commit }, filter: INumberFilter) {
        commit(NumberMutations.SET_FILTER, filter);
    },
    [NumberActions.CLEAR_FILTER]({ commit }, field?: string) {
        commit(NumberMutations.CLEAR_FILTER, field);
    },
};

const getters: GetterTree<INumberState, INumberState> = {
    [NumberGetters.SEARCH](state): string | null {
        return state.search;
    },
    [NumberGetters.SORT](state): ISortMenuItem[] {
        return state.sort;
    },
    [NumberGetters.PAGE](state): number {
        return state.page;
    },
    [NumberGetters.FILTER](state): INumberFilter {
        return state.filter;
    },
    [NumberGetters.DEFAULT_FILTER](): INumberFilter {
        return getDefaultState().filter;
    },
    [NumberGetters.ACTIVE_FILTERS](state): IFilterItem[] {
        const filters: IFilterItem[] = [];

        if (state.filter.isActive) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        if (state.filter.company) {
            filters.push({
                field: 'company',
                value: state.filter.company.value,
                label: state.filter.company.label,
            });
        }

        if (state.filter.level) {
            filters.push({
                field: 'level',
                value: state.filter.level.value,
                label: state.filter.level.label,
            });
        }

        if (state.filter.type) {
            filters.push({
                field: 'type',
                value: state.filter.type.value,
                label: state.filter.type.label,
            });
        }

        if (state.filter.state) {
            filters.push({
                field: 'state',
                value: state.filter.state.value,
                label: state.filter.state.label,
            });
        }

        if (state.filter.outboundOperator) {
            filters.push({
                field: 'outboundOperator',
                value: state.filter.outboundOperator.value,
                label: state.filter.outboundOperator.label,
            });
        }

        if (state.filter.calltrackingOperator) {
            filters.push({
                field: 'calltrackingOperator',
                value: state.filter.calltrackingOperator.value,
                label: state.filter.calltrackingOperator.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
