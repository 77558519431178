import { RouteRecordRaw } from 'vue-router';
import { idIsPositiveInteger } from '@/router/routeGuards';
import CompanyPage from '@/views/pages/company/CompanyPage.vue';
import CompanyDetailPage from '@/views/pages/company/CompanyDetailPage.vue';
import CompanyFormPage from '@/views/pages/company/CompanyFormPage.vue';
import { FormAction } from '@/definitions';

const companyRouter: RouteRecordRaw = {
    name: 'Company',
    path: 'company',
    children: [
        {
            name: 'ListCompany',
            path: '',
            component: CompanyPage,
        },
        {
            name: 'ViewCompanyById',
            path: ':id',
            component: CompanyDetailPage,
            beforeEnter: [idIsPositiveInteger],
        },
        {
            name: 'EditCompanyById',
            path: ':id/edit',
            component: CompanyFormPage,
            beforeEnter: [idIsPositiveInteger],
            props: { action: FormAction.EDIT },
        },
        {
            name: 'CreateCompany',
            path: 'create',
            component: CompanyFormPage,
            props: { action: FormAction.CREATE },
        },
    ],
};

export default companyRouter;
