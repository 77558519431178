import { ILevel, IDefaultServiceResult } from '@/definitions';
import BaseService from '@/services/BaseService';

class LevelService extends BaseService<ILevel> {
    activate = (id: number) => {
        return this.http.patch<IDefaultServiceResult>(`${this.route}/${id}`, { isActive: true });
    };

    inactivate = (id: number) => {
        return this.http.patch<IDefaultServiceResult>(`${this.route}/${id}`, { isActive: false });
    };
}

export interface ILevelServiceCreateResult extends IDefaultServiceResult {
    result: ILevel;
}

export default new LevelService('levels');
