import * as validators from '@vuelidate/validators';
import { i18n } from '@/utils/locale';
import { Ref } from 'vue';
import { ValidationRuleWithoutParams, ValidationRule } from '@vuelidate/core';

const { createI18nMessage } = validators;

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

// Basic Validators
export const required: ValidationRuleWithoutParams = withI18nMessage(validators.required);
export const requiredIf: (
    prop: boolean | Ref<boolean> | string | (() => boolean | Promise<boolean>),
) => ValidationRuleWithoutParams = withI18nMessage(validators.requiredIf, {
    withArguments: true,
});
export const requiredUnless: (
    prop: boolean | Ref<boolean> | string | (() => boolean | Promise<boolean>),
) => ValidationRuleWithoutParams = (prop) =>
    withI18nMessage(validators.requiredUnless(prop), { withArguments: true });
export const sameAs: <E = unknown>(equalTo: E | Ref<E>, otherName?: string) => ValidationRule =
    withI18nMessage(validators.sameAs, {
        withArguments: true,
    });

// String Validators
export const alpha: ValidationRuleWithoutParams = withI18nMessage(validators.alpha);
export const alphaNum: ValidationRuleWithoutParams = withI18nMessage(validators.alphaNum);
export const minLength: (min: number | Ref<number>) => ValidationRule = withI18nMessage(
    validators.minLength,
    {
        withArguments: true,
    },
);
export const maxLength: (max: number | Ref<number>) => ValidationRule = withI18nMessage(
    validators.maxLength,
    {
        withArguments: true,
    },
);
export const email: ValidationRuleWithoutParams = withI18nMessage(validators.email);
export const url: ValidationRuleWithoutParams = withI18nMessage(validators.url);

// Numeric Validators
export const numeric: ValidationRuleWithoutParams = withI18nMessage(validators.numeric);
export const integer: ValidationRuleWithoutParams = withI18nMessage(validators.integer);
export const decimal: ValidationRuleWithoutParams = withI18nMessage(validators.decimal);
export const between: (min: number | Ref<number>, max: number | Ref<number>) => ValidationRule = (
    min,
    max,
) => withI18nMessage(validators.between(min, max), { withArguments: true });
export const minValue: (min: number | Ref<number> | string | Ref<string>) => ValidationRule = (
    min,
) => withI18nMessage(validators.minValue(min), { withArguments: true });
export const maxValue: (max: number | Ref<number> | string | Ref<string>) => ValidationRule = (
    max,
) => withI18nMessage(validators.maxValue(max), { withArguments: true });

// Logical Validators
export const and: <T = unknown>(
    ...validatorsList: ValidationRule<T>[]
) => ValidationRuleWithoutParams = (...validatorsList) =>
    withI18nMessage(validators.and(...validatorsList));
export const or: <T = unknown>(
    ...validatorsList: ValidationRule<T>[]
) => ValidationRuleWithoutParams = (...validatorsList) =>
    withI18nMessage(validators.or(...validatorsList));
export const not: <T = unknown>(validator: ValidationRule<T>) => ValidationRuleWithoutParams = (
    validator,
) => withI18nMessage(validators.not(validator));

// Address Validators
export const ipAddress: ValidationRuleWithoutParams = withI18nMessage(validators.ipAddress);
export const macAddress: (separator: string | Ref<string>) => ValidationRuleWithoutParams = (
    separator,
) => withI18nMessage(validators.macAddress(separator), { withArguments: true });

export const containsUppercase: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        return /[A-Z]/.test(value || '');
    },
});
export const containsLowercase: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        return /[a-z]/.test(value || '');
    },
});
export const containsNumber: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        return /[0-9]/.test(value || '');
    },
});
export const containsSpecial: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        return /[#?!@$%^&*-]/.test(value || '');
    },
});

export const atLeastOneFieldFilled: ValidationRule = (value) => {
    if (!value) return true;
    const fields = ['audio', 'audioFilePath', 'textToAudio'];
    return fields.some((field) => value[field]);
};
