import { IListable, IMultiSelectOption, IRole } from '@/definitions';

export interface IUserFilter {
    roles: IRole[];
    isActive: IMultiSelectOption | null;
}
export interface IUserState extends IListable {
    filter: IUserFilter;
}

export enum UserMutations {
    SET_SEARCH = 'user_setSearch',
    SET_SORT = 'user_setSort',
    SET_PAGE = 'user_setPage',
    SET_FILTER = 'user_setFilter',
    CLEAR_FILTER = 'user_clearFilter',
}

export enum UserActions {
    SET_SEARCH = 'user_setSearch',
    SET_SORT = 'user_setSort',
    SET_PAGE = 'user_setPage',
    SET_FILTER = 'user_setFilter',
    CLEAR_FILTER = 'user_clearFilter',
}

export enum UserGetters {
    SEARCH = 'user_search',
    SORT = 'user_sort',
    PAGE = 'user_page',
    FILTER = 'user_filter',
    DEFAULT_FILTER = 'user_default_filter',
    ACTIVE_FILTERS = 'user_active_filters',
}
