export interface IFilterState {
    dateRange: Array<Date> | null;
}

export enum FilterMutations {
    SET_DATE_RANGE = 'filter_setDateRange',
}

export enum FilterActions {
    SET_DATE_RANGE = 'filter_setDateRange',
}

export enum FilterGetters {
    DATE_RANGE = 'filter_dateRange',
}
