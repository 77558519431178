/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import router from '@/router';
import {
    AuthActions,
    AuthGetters,
    AuthMutations,
    IAuthServiceLoginResult,
    IAuthState,
    IUser,
    Permission,
} from '@/definitions';

const state: IAuthState = {
    user: undefined,
};

const mutations: MutationTree<IAuthState> = {
    [AuthMutations.SET_USER](state, user: IUser) {
        state.user = user;
    },
};

const actions: ActionTree<IAuthState, any> = {
    [AuthActions.LOGIN]({ commit }, loginResult: IAuthServiceLoginResult) {
        const { user } = loginResult;

        commit(AuthMutations.SET_USER, user);

        if (loginResult.jwt) {
            localStorage.setItem('token', loginResult.jwt);
        }
    },
    [AuthActions.LOGOUT]({ commit }) {
        commit(AuthMutations.SET_USER, null);
        localStorage.removeItem('token');
        localStorage.removeItem('locale');
        router.push('/login').then();
    },
};

const getters: GetterTree<IAuthState, any> = {
    [AuthGetters.USER]: (state) => state.user,
    [AuthGetters.USER_ROLES]: (state) => state.user?.roles,
    [AuthGetters.USER_PERMISSIONS]: (state) => {
        const permissions: Permission[] = [];

        const roles = state.user?.roles || [];
        for (let i = 0; i < roles.length; i++) {
            const rolePermissions = roles[i].permissions || [];
            for (let ii = 0; ii < rolePermissions.length; ii++) {
                const rolePermission = rolePermissions[ii].id as Permission;
                if (
                    Object.values(Permission).includes(rolePermission) &&
                    !permissions.includes(rolePermission)
                )
                    permissions.push(rolePermission);
            }
        }

        return permissions;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
