import { IListable, ICompany, ILevel, IMultiSelectOption } from '@/definitions';

export interface IApiTokenFilter {
    company: ICompany | null;
    levels: ILevel[] | null;
    isActive: IMultiSelectOption | null;
}
export interface IApiTokenState extends IListable {
    filter: IApiTokenFilter;
}

export enum ApiTokenMutations {
    SET_SEARCH = 'apiToken_setSearch',
    SET_SORT = 'apiToken_setSort',
    SET_PAGE = 'apiToken_setPage',
    SET_FILTER = 'apiToken_setFilter',
    CLEAR_FILTER = 'apiToken_clearFilter',
}

export enum ApiTokenActions {
    SET_SEARCH = 'apiToken_setSearch',
    SET_SORT = 'apiToken_setSort',
    SET_PAGE = 'apiToken_setPage',
    SET_FILTER = 'apiToken_setFilter',
    CLEAR_FILTER = 'apiToken_clearFilter',
}

export enum ApiTokenGetters {
    SEARCH = 'apiToken_search',
    SORT = 'apiToken_sort',
    PAGE = 'apiToken_page',
    FILTER = 'apiToken_filter',
    DEFAULT_FILTER = 'apiToken_default_filter',
    ACTIVE_FILTERS = 'apiToken_active_filters',
}
