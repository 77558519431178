import { IListable, IMultiSelectOption } from '@/definitions';

export interface ICompanyFilter {
    isActive: IMultiSelectOption | null;
    migrated: IMultiSelectOption | null;
}

export interface ICompanyState extends IListable {
    filter: ICompanyFilter;
}

export enum CompanyMutations {
    SET_SEARCH = 'company_setSearch',
    SET_SORT = 'company_setSort',
    SET_PAGE = 'company_setPage',
    SET_FILTER = 'company_setFilter',
    CLEAR_FILTER = 'company_clearFilter',
}

export enum CompanyActions {
    SET_SEARCH = 'company_setSearch',
    SET_SORT = 'company_setSort',
    SET_PAGE = 'company_setPage',
    SET_FILTER = 'company_setFilter',
    CLEAR_FILTER = 'company_clearFilter',
}

export enum CompanyGetters {
    SEARCH = 'company_search',
    SORT = 'company_sort',
    PAGE = 'company_page',
    FILTER = 'company_filter',
    DEFAULT_FILTER = 'company_default_filter',
    ACTIVE_FILTERS = 'company_active_filters',
}
