export interface IUiState {
    isLoading: boolean;
    locale: string;
}

export enum UiMutations {
    SET_IS_LOADING = 'ui_setIsLoading',
    SET_LOCALE = 'ui_setLocale',
}

export enum UiActions {
    SET_IS_LOADING = 'ui_setIsLoading',
    SET_LOCALE = 'ui_setLocale',
    START_UP_LOCALE = 'ui_startUpLocale',
}

export enum UiGetters {
    IS_LOADING = 'ui_isLoading',
    CURRENT_LOCALE = 'ui_currentLocale',
}
