import axios from 'axios';

const instance = axios.create({
    baseURL: ['production', 'staging'].includes(process.env.NODE_ENV)
        ? '/api'
        : 'http://localhost:3000/api',
    headers: {
        'Content-type': 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        config.headers.authorization = 'Bearer ' + (localStorage.getItem('token') ?? '');
        config.headers['Accept-Language'] = localStorage.getItem('locale') ?? '';

        return config;
    },
    (error) => Promise.reject(error),
);

export default instance;
