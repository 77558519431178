import { IDefaultServiceResult, IScript } from '@/definitions';
import BaseService from '@/services/BaseService';

class ScriptService extends BaseService<IScript> {
    activate = (id: number | string) => {
        return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
    };
    inactivate = (id: number | string) => {
        return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/inactivate`);
    };
}

export default new ScriptService('scripts');
