import { RouteRecordRaw } from 'vue-router';
import { idIsPositiveInteger } from '@/router/routeGuards';
import ScriptPage from '@/views/pages/script/ScriptPage.vue';
import ScriptDetailPage from '@/views/pages/script/ScriptDetailPage.vue';
import ScriptFormPage from '@/views/pages/script/ScriptFormPage.vue';
import { FormAction } from '@/definitions';

const scriptRouter: RouteRecordRaw = {
    name: 'Script',
    path: 'script',
    children: [
        {
            name: 'ListScript',
            path: '',
            component: ScriptPage,
        },
        {
            name: 'ViewScriptById',
            path: ':id',
            component: ScriptDetailPage,
            beforeEnter: [idIsPositiveInteger],
        },
        {
            name: 'EditScriptById',
            path: ':id/edit',
            component: ScriptFormPage,
            beforeEnter: [idIsPositiveInteger],
            props: { action: FormAction.EDIT },
        },
        {
            name: 'CreateScript',
            path: 'create',
            component: ScriptFormPage,
            props: { action: FormAction.CREATE },
        },
    ],
};

export default scriptRouter;
