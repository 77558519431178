import { App } from 'vue';
import { can } from '@/utils/auth';
import { Permission } from '@/definitions';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $window: Window;
        $can: (permission: Permission) => boolean;
    }
}

const globalProperties = {
    install(app: App<Element>) {
        app.config.globalProperties.$window = window;
        app.config.globalProperties.$can = can;
    },
};

export default globalProperties;
