<template>
    <div
        class="-mx-4 mt-8 px-4 py-5 bg-white shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg"
    >
        <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
                <slot name="title"></slot>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>
