import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    IApiTokenState,
    ApiTokenMutations,
    ApiTokenActions,
    ApiTokenGetters,
    IApiTokenFilter,
    ISortMenuItem,
    IFilterItem,
} from '@/definitions';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            company: null,
            levels: null,
            isActive: null,
        },
    } as IApiTokenState;
};

const state: IApiTokenState = getDefaultState();

const mutations: MutationTree<IApiTokenState> = {
    [ApiTokenMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [ApiTokenMutations.SET_SORT]: (state, sort: ISortMenuItem[]) => (state.sort = sort),
    [ApiTokenMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [ApiTokenMutations.SET_FILTER](state, filter: IApiTokenFilter) {
        state.filter.company = filter.company;
        state.filter.levels = filter.levels;
        state.filter.isActive = filter.isActive;
    },
    [ApiTokenMutations.CLEAR_FILTER](state, field?: string) {
        // This makes no sense but ok...
        switch (field) {
            case 'company':
                state.filter.company = getDefaultState().filter.company;
                break;
            case 'levels':
                state.filter.levels = getDefaultState().filter.levels;
                break;
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            default:
                state.filter.company = getDefaultState().filter.company;
                state.filter.levels = getDefaultState().filter.levels;
                state.filter.isActive = getDefaultState().filter.isActive;
        }
    },
};

const actions: ActionTree<IApiTokenState, unknown> = {
    [ApiTokenActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(ApiTokenMutations.SET_SEARCH, search),
    [ApiTokenActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
        commit(ApiTokenMutations.SET_SORT, sort),
    [ApiTokenActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(ApiTokenMutations.SET_PAGE, page),
    [ApiTokenActions.SET_FILTER]: ({ commit }, filter: IApiTokenFilter) =>
        commit(ApiTokenMutations.SET_FILTER, filter),
    [ApiTokenActions.CLEAR_FILTER]: ({ commit }, filter: IApiTokenFilter) =>
        commit(ApiTokenMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<IApiTokenState, unknown> = {
    [ApiTokenGetters.SEARCH]: (state): string | null => state.search,
    [ApiTokenGetters.SORT]: (state): ISortMenuItem[] => state.sort,
    [ApiTokenGetters.PAGE]: (state): number => state.page,
    [ApiTokenGetters.FILTER]: (state): IApiTokenFilter => state.filter,
    [ApiTokenGetters.DEFAULT_FILTER]: (): IApiTokenFilter => getDefaultState().filter,
    [ApiTokenGetters.ACTIVE_FILTERS](state): IFilterItem[] {
        const filters: Array<IFilterItem> = [];

        if (state.filter.company !== null) {
            filters.push({
                field: 'company',
                value: state.filter.company.id,
                label: state.filter.company.name,
            });
        }

        if (state.filter.levels !== null) {
            state.filter.levels.forEach((level) => {
                filters.push({
                    field: 'levels',
                    value: level.id,
                    label: level.name,
                });
            });
        }

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
