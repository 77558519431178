import { IUser, IAuthServiceLoginBody, IAuthServiceLoginResult } from '@/definitions';
import BaseService from '@/services/BaseService';

class AuthService extends BaseService<IUser> {
    constructor() {
        super('');
    }

    async login(loginBody: IAuthServiceLoginBody) {
        return this.http.post<IAuthServiceLoginResult>('auth/login', loginBody);
    }

    async check() {
        return this.http.get<IAuthServiceLoginResult>('auth/check');
    }
}

export default new AuthService();
