import { ILevel } from '../core-typings/ILevel';

export interface INumberFormState {
    isActive: boolean;
    level?: ILevel;
    levelId: number;
    number: string;
    city: string;
    state: string;
    type: string;
    mediaLabel?: string;
    outboundOperator: string;
    calltrackingOperator?: string;
}

export interface IState {
    label: string;
    value: string;
}

export interface INumberType {
    label: string;
    value: string;
}

export const typeList: INumberType[] = [
    { label: 'Local', value: 'local' },
    { label: 'Nacional', value: 'national' },
    { label: 'Móvel', value: 'mobile' },
];

export const stateList: IState[] = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
    { label: 'Nacional', value: 'NC' },
];

export interface IOperator {
    label: string;
    value: string;
}

export const operatorList: IOperator[] = [
    { label: 'Algar', value: 'algar' },
    { label: 'Americanet', value: 'americanet' },
    { label: 'DirectCall', value: 'directcall' },
    { label: 'DirectCall Ilimitado', value: 'directcall_ilimitado' },
    { label: 'Mega', value: 'mega' },
    { label: 'Surf', value: 'surf' },
    { label: 'Tip', value: 'tip' },
    { label: 'Vonex', value: 'vonex' },
    { label: 'Voxvision', value: 'voxvision' },
];
