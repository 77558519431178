import { RouteRecordRaw } from 'vue-router';
import RequestPage from '@/views/pages/request/RequestPage.vue';

const requestRouter: RouteRecordRaw = {
    name: 'Request',
    path: 'request',
    children: [
        {
            name: 'ListRequest',
            path: '',
            component: RequestPage,
        },
    ],
};

export default requestRouter;
