/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    ILevelState,
    LevelMutations,
    LevelActions,
    LevelGetters,
    ILevelFilter,
    ISortMenuItem,
    IFilterItem,
} from '@/definitions';

const getDefaultState = (): ILevelState => ({
    search: null,
    sort: [],
    page: 1,
    filter: {
        isActive: null,
        migrated: null,
        companyId: null,
    },
});

const state: ILevelState = getDefaultState();

const mutations: MutationTree<ILevelState> = {
    [LevelMutations.SET_SEARCH](state, search: string) {
        state.search = search;
    },
    [LevelMutations.SET_SORT](state, sort: ISortMenuItem[]) {
        state.sort = sort;
    },
    [LevelMutations.SET_PAGE](state, page: number) {
        state.page = page;
    },
    [LevelMutations.SET_FILTER](state, filter: ILevelFilter) {
        state.filter.isActive = filter.isActive;
        state.filter.migrated = filter.migrated;
        state.filter.companyId = filter.companyId;
    },
    [LevelMutations.CLEAR_FILTER](state, field?: string) {
        switch (field) {
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            case 'migrated':
                state.filter.migrated = getDefaultState().filter.migrated;
                break;
            case 'companyId':
                state.filter.companyId = getDefaultState().filter.companyId;
                break;
            default:
                state.filter.isActive = getDefaultState().filter.isActive;
                state.filter.migrated = getDefaultState().filter.migrated;
                state.filter.companyId = getDefaultState().filter.companyId;
        }
    },
};

const actions: ActionTree<ILevelState, ILevelState> = {
    [LevelActions.SET_SEARCH]({ commit }, search: string) {
        commit(LevelMutations.SET_SEARCH, search);
    },
    [LevelActions.SET_SORT]({ commit }, sort: ISortMenuItem[]) {
        commit(LevelMutations.SET_SORT, sort);
    },
    [LevelActions.SET_PAGE]({ commit }, page: number) {
        commit(LevelMutations.SET_PAGE, page);
    },
    [LevelActions.SET_FILTER]({ commit }, filter: ILevelFilter) {
        commit(LevelMutations.SET_FILTER, filter);
    },
    [LevelActions.CLEAR_FILTER]({ commit }, field?: string) {
        commit(LevelMutations.CLEAR_FILTER, field);
    },
};

const getters: GetterTree<ILevelState, ILevelState> = {
    [LevelGetters.SEARCH](state): string | null {
        return state.search;
    },
    [LevelGetters.SORT](state): ISortMenuItem[] {
        return state.sort;
    },
    [LevelGetters.PAGE](state): number {
        return state.page;
    },
    [LevelGetters.FILTER](state): ILevelFilter {
        return state.filter;
    },
    [LevelGetters.DEFAULT_FILTER](): ILevelFilter {
        return getDefaultState().filter;
    },
    [LevelGetters.ACTIVE_FILTERS](state): IFilterItem[] {
        const filters: IFilterItem[] = [];

        if (state.filter.isActive) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        if (state.filter.migrated) {
            filters.push({
                field: 'migrated',
                value: state.filter.migrated.value,
                label: state.filter.migrated.label,
            });
        }

        if (state.filter.companyId) {
            filters.push({
                field: 'companyId',
                value: state.filter.companyId.value,
                label: state.filter.companyId.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
