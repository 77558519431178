import { IListable, IMultiSelectOption } from '@/definitions';

export interface ILevelFilter {
    isActive: IMultiSelectOption | null;
    migrated: IMultiSelectOption | null;
    companyId: IMultiSelectOption | null;
}

export interface ILevelState extends IListable {
    filter: ILevelFilter;
}

export enum LevelMutations {
    SET_SEARCH = 'level_setSearch',
    SET_SORT = 'level_setSort',
    SET_PAGE = 'level_setPage',
    SET_FILTER = 'level_setFilter',
    CLEAR_FILTER = 'level_clearFilter',
}

export enum LevelActions {
    SET_SEARCH = 'level_setSearch',
    SET_SORT = 'level_setSort',
    SET_PAGE = 'level_setPage',
    SET_FILTER = 'level_setFilter',
    CLEAR_FILTER = 'level_clearFilter',
}

export enum LevelGetters {
    SEARCH = 'level_search',
    SORT = 'level_sort',
    PAGE = 'level_page',
    FILTER = 'level_filter',
    DEFAULT_FILTER = 'level_default_filter',
    ACTIVE_FILTERS = 'level_active_filters',
}
