import { IDefaultServiceResult, ICompany } from '@/definitions';
import BaseService from '@/services/BaseService';

class CompanyService extends BaseService<ICompany> {
    activate = (id: number | string) => {
        return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
    };
    inactivate = (id: number | string) => {
        return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/inactivate`);
    };
}

export interface ICompanyServiceCreateResult extends IDefaultServiceResult {
    result: ICompany;
}

export default new CompanyService('companies');
