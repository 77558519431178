export enum IMultiselectMode {
    SINGLE = 'single',
    MULTIPLE = 'tags',
    TAGS = 'tags',
}

export interface IMultiSelectOption<valueType = object | string | number | boolean | null> {
    label: string;
    value: valueType;
}

export interface ICityOption extends IMultiSelectOption {
    state?: string;
}
