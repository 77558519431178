import { IListable, IMultiSelectOption, REQUEST_TYPE, REQUEST_STATUS } from '@/definitions';

export interface IRequestFilter {
    types: IMultiSelectOption<REQUEST_TYPE>[] | null;
    status: IMultiSelectOption<REQUEST_STATUS>[] | null;
}
export interface IRequestState extends IListable {
    filter: IRequestFilter;
}

export enum RequestMutations {
    SET_SEARCH = 'request_setSearch',
    SET_SORT = 'request_setSort',
    SET_PAGE = 'request_setPage',
    SET_FILTER = 'request_setFilter',
    CLEAR_FILTER = 'request_clearFilter',
}

export enum RequestActions {
    SET_SEARCH = 'request_setSearch',
    SET_SORT = 'request_setSort',
    SET_PAGE = 'request_setPage',
    SET_FILTER = 'request_setFilter',
    CLEAR_FILTER = 'request_clearFilter',
}

export enum RequestGetters {
    SEARCH = 'request_search',
    SORT = 'request_sort',
    PAGE = 'request_page',
    FILTER = 'request_filter',
    DEFAULT_FILTER = 'request_default_filter',
    ACTIVE_FILTERS = 'request_active_filters',
}
