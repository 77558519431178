import { IListable } from '@/definitions';

export type IPermissionState = IListable;

export enum PermissionMutations {
    SET_SEARCH = 'permission_setSearch',
    SET_SORT = 'permission_setSort',
    SET_PAGE = 'permission_setPage',
}

export enum PermissionActions {
    SET_SEARCH = 'permission_setSearch',
    SET_SORT = 'permission_setSort',
    SET_PAGE = 'permission_setPage',
}

export enum PermissionGetters {
    SEARCH = 'permission_search',
    SORT = 'permission_sort',
    PAGE = 'permission_page',
}
