import { INumber, IDefaultServiceResult } from '@/definitions';
import BaseService from '@/services/BaseService';

class NumberService extends BaseService<INumber> {
    activate = (id: number) => {
        return this.http.patch<IDefaultServiceResult>(`${this.route}/${id}`, { isActive: true });
    };

    inactivate = (id: number) => {
        return this.http.patch<IDefaultServiceResult>(`${this.route}/${id}`, { isActive: false });
    };
}

export interface INumberServiceCreateResult extends IDefaultServiceResult {
    result: INumber;
}

export default new NumberService('numbers');
