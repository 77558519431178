import moment from 'moment';

const formatDateAsString = (dateAsString: string | undefined, format?: string) => {
    return moment(dateAsString).format(format || 'LLLL');
};

const formatSecToTime = (seconds: number, forceHours?: boolean): string => {
    let secs = Math.floor(seconds);
    let minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);

    secs %= 60;
    minutes %= 60;

    let strHours = '';
    if (hours || forceHours) {
        strHours = `${('0' + hours).slice(-2)}:`;
    }

    return `${strHours}${('0' + minutes).slice(-2)}:${('0' + secs).slice(-2)}`;
};

export { moment, formatDateAsString, formatSecToTime };

export default moment;
