import { RouteRecordRaw } from 'vue-router';
import { idIsPositiveInteger } from '@/router/routeGuards';
import ApiTokenPage from '@/views/pages/api-token/ApiTokenPage.vue';
import ApiTokenDetailPage from '@/views/pages/api-token/ApiTokenDetailPage.vue';
import ApiTokenFormPage from '@/views/pages/api-token/ApiTokenFormPage.vue';
import { FormAction } from '@/definitions';

const apiTokenRouter: RouteRecordRaw = {
    name: 'ApiToken',
    path: 'apiToken',
    children: [
        {
            name: 'ListApiToken',
            path: '',
            component: ApiTokenPage,
        },
        {
            name: 'ViewApiTokenById',
            path: ':id',
            component: ApiTokenDetailPage,
            beforeEnter: [idIsPositiveInteger],
        },
        {
            name: 'EditApiTokenById',
            path: ':id/edit',
            component: ApiTokenFormPage,
            beforeEnter: [idIsPositiveInteger],
            props: { action: FormAction.EDIT },
        },
        {
            name: 'CreateApiToken',
            path: 'create',
            component: ApiTokenFormPage,
            props: { action: FormAction.CREATE },
        },
    ],
};

export default apiTokenRouter;
