import { IListable, IMultiSelectOption } from '@/definitions';

export interface INumberFilter {
    isActive: IMultiSelectOption | null;
    company: IMultiSelectOption | null;
    level: IMultiSelectOption | null;
    type: IMultiSelectOption | null;
    state: IMultiSelectOption | null;
    outboundOperator: IMultiSelectOption | null;
    calltrackingOperator: IMultiSelectOption | null;
}

export interface INumberState extends IListable {
    filter: INumberFilter;
}

export enum NumberMutations {
    SET_SEARCH = 'number_setSearch',
    SET_SORT = 'number_setSort',
    SET_PAGE = 'number_setPage',
    SET_FILTER = 'number_setFilter',
    CLEAR_FILTER = 'number_clearFilter',
}

export enum NumberActions {
    SET_SEARCH = 'number_setSearch',
    SET_SORT = 'number_setSort',
    SET_PAGE = 'number_setPage',
    SET_FILTER = 'number_setFilter',
    CLEAR_FILTER = 'number_clearFilter',
}

export enum NumberGetters {
    SEARCH = 'number_search',
    SORT = 'number_sort',
    PAGE = 'number_page',
    FILTER = 'number_filter',
    DEFAULT_FILTER = 'number_default_filter',
    ACTIVE_FILTERS = 'number_active_filters',
}
