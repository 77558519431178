/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    IRequestState,
    RequestMutations,
    RequestActions,
    RequestGetters,
    IRequestFilter,
    ISortMenuItem,
    IFilterItem,
} from '@/definitions';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            types: null,
            status: null,
        },
    } as IRequestState;
};

const state: IRequestState = getDefaultState();

const mutations: MutationTree<IRequestState> = {
    [RequestMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [RequestMutations.SET_SORT]: (state, sort: ISortMenuItem[]) => (state.sort = sort),
    [RequestMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [RequestMutations.SET_FILTER](state, filter: IRequestFilter) {
        state.filter.types = filter.types;
        state.filter.status = filter.status;
    },
    [RequestMutations.CLEAR_FILTER](state, field?: string) {
        // This makes no sense but ok...
        switch (field) {
            case 'role':
                state.filter.types = getDefaultState().filter.types;
                break;
            case 'status':
                state.filter.status = getDefaultState().filter.status;
                break;
            default:
                state.filter.types = getDefaultState().filter.types;
                state.filter.status = getDefaultState().filter.status;
        }
    },
};

const actions: ActionTree<IRequestState, any> = {
    [RequestActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(RequestMutations.SET_SEARCH, search),
    [RequestActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
        commit(RequestMutations.SET_SORT, sort),
    [RequestActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(RequestMutations.SET_PAGE, page),
    [RequestActions.SET_FILTER]: ({ commit }, filter: IRequestFilter) =>
        commit(RequestMutations.SET_FILTER, filter),
    [RequestActions.CLEAR_FILTER]: ({ commit }, filter: IRequestFilter) =>
        commit(RequestMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<IRequestState, any> = {
    [RequestGetters.SEARCH]: (state): string | null => state.search,
    [RequestGetters.SORT]: (state): ISortMenuItem[] => state.sort,
    [RequestGetters.PAGE]: (state): number => state.page,
    [RequestGetters.FILTER]: (state): IRequestFilter => state.filter,
    [RequestGetters.DEFAULT_FILTER]: (): IRequestFilter => getDefaultState().filter,
    [RequestGetters.ACTIVE_FILTERS](state): IFilterItem[] {
        const filters: Array<IFilterItem> = [];

        if (state.filter.types !== null) {
            state.filter.types.forEach((type) => {
                filters.push({
                    field: 'types',
                    value: type.value,
                    label: type.label,
                });
            });
        }

        if (state.filter.status !== null) {
            state.filter.status.forEach((status) => {
                filters.push({
                    field: 'status',
                    value: status.value,
                    label: status.label,
                });
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
