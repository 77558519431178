import { RouteRecordRaw } from 'vue-router';
import { idIsPositiveInteger } from '@/router/routeGuards';
import { FormAction } from '@/definitions';
import RoleDetailPage from '@/views/pages/role/RoleDetailPage.vue';
import RoleFormPage from '@/views/pages/role/RoleFormPage.vue';

const roleRouter: RouteRecordRaw = {
    name: 'Role',
    path: 'role',
    children: [
        {
            name: 'ViewRoleById',
            path: ':id',
            component: RoleDetailPage,
            beforeEnter: [idIsPositiveInteger],
        },
        {
            name: 'EditRoleById',
            path: ':id/edit',
            component: RoleFormPage,
            beforeEnter: [idIsPositiveInteger],
            props: { action: FormAction.EDIT },
        },
        {
            name: 'CreateRole',
            path: 'create',
            component: RoleFormPage,
            props: { action: FormAction.CREATE },
        },
    ],
};

export default roleRouter;
