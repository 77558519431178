import { IListable, ICompany, ILevel, IMultiSelectOption } from '@/definitions';

export interface IScriptFilter {
    company: ICompany | null;
    levels: ILevel[] | null;
    isActive: IMultiSelectOption | null;
}
export interface IScriptState extends IListable {
    filter: IScriptFilter;
}

export enum ScriptMutations {
    SET_SEARCH = 'script_setSearch',
    SET_SORT = 'script_setSort',
    SET_PAGE = 'script_setPage',
    SET_FILTER = 'script_setFilter',
    CLEAR_FILTER = 'script_clearFilter',
}

export enum ScriptActions {
    SET_SEARCH = 'script_setSearch',
    SET_SORT = 'script_setSort',
    SET_PAGE = 'script_setPage',
    SET_FILTER = 'script_setFilter',
    CLEAR_FILTER = 'script_clearFilter',
}

export enum ScriptGetters {
    SEARCH = 'script_search',
    SORT = 'script_sort',
    PAGE = 'script_page',
    FILTER = 'script_filter',
    DEFAULT_FILTER = 'script_default_filter',
    ACTIVE_FILTERS = 'script_active_filters',
}
