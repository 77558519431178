import store from '@/store';
import AuthService from '@/services/AuthService';
import { NavigationGuardNext, NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';
import { isPositiveInteger } from '@/utils/number';
import { AuthActions, AuthGetters } from '@/definitions';

export const beforeEach: NavigationGuardWithThis<undefined> = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) => {
    const noCheck = ['/app/login'];

    if (store.getters[AuthGetters.USER] === undefined) {
        if (noCheck.indexOf(to.path) != -1) {
            next();
        } else if (to.path != '/login') {
            AuthService.check().then(
                (response) => {
                    store.dispatch(AuthActions.LOGIN, response.data);
                    next();
                },
                () => next({ name: 'Login' }),
            );
        } else if (to.path === '/login') {
            AuthService.check().then(
                (response) => {
                    store.dispatch(AuthActions.LOGIN, response.data);
                    next({ name: 'Home' });
                },
                () => next(),
            );
        }
    } else {
        next();
    }
};

export const idIsPositiveInteger: NavigationGuardWithThis<undefined> = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
) => {
    const id: string | string[] = to.params.id;
    if (typeof id === 'string' && isPositiveInteger(id)) next();
    else next('not-found');
};
