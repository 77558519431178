import { IRequest, IDefaultServiceResult } from '@/definitions';
import BaseService from '@/services/BaseService';

class RequestService extends BaseService<IRequest> {
    cancel = (id: number | string) => {
        return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/cancel`);
    };
    finish = (id: number | string) => {
        return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/finish`);
    };
}

export default new RequestService('requests');
