/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    ICompanyState,
    CompanyMutations,
    CompanyActions,
    CompanyGetters,
    ICompanyFilter,
    ISortMenuItem,
    IFilterItem,
} from '@/definitions';

const getDefaultState = (): ICompanyState => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            isActive: null,
            migrated: null,
        },
    } as ICompanyState;
};

const state: ICompanyState = getDefaultState();

const mutations: MutationTree<ICompanyState> = {
    [CompanyMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [CompanyMutations.SET_SORT]: (state, sort: ISortMenuItem[]) => (state.sort = sort),
    [CompanyMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [CompanyMutations.SET_FILTER](state, filter: ICompanyFilter) {
        state.filter.isActive = filter.isActive;
        state.filter.migrated = filter.migrated;
    },
    [CompanyMutations.CLEAR_FILTER](state, field?: string) {
        switch (field) {
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            case 'migrated':
                state.filter.migrated = getDefaultState().filter.migrated;
                break;
            default:
                state.filter.isActive = getDefaultState().filter.isActive;
                state.filter.migrated = getDefaultState().filter.migrated;
        }
    },
};

const actions: ActionTree<ICompanyState, any> = {
    [CompanyActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(CompanyMutations.SET_SEARCH, search),
    [CompanyActions.SET_SORT]: ({ commit }, sort: ISortMenuItem[]) =>
        commit(CompanyMutations.SET_SORT, sort),
    [CompanyActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(CompanyMutations.SET_PAGE, page),
    [CompanyActions.SET_FILTER]: ({ commit }, filter: ICompanyFilter) =>
        commit(CompanyMutations.SET_FILTER, filter),
    [CompanyActions.CLEAR_FILTER]: ({ commit }, field?: string) =>
        commit(CompanyMutations.CLEAR_FILTER, field),
};

const getters: GetterTree<ICompanyState, any> = {
    [CompanyGetters.SEARCH]: (state): string | null => state.search,
    [CompanyGetters.SORT]: (state): ISortMenuItem[] => state.sort,
    [CompanyGetters.PAGE]: (state): number => state.page,
    [CompanyGetters.FILTER]: (state): ICompanyFilter => state.filter,
    [CompanyGetters.DEFAULT_FILTER]: (): ICompanyFilter => getDefaultState().filter,
    [CompanyGetters.ACTIVE_FILTERS](state): IFilterItem[] {
        const filters: Array<IFilterItem> = [];

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        if (state.filter.migrated !== null) {
            filters.push({
                field: 'migrated',
                value: state.filter.migrated.value,
                label: state.filter.migrated.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
