/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    INavigationState,
    NavigationMutations,
    NavigationActions,
    NavigationGetters,
    SIDE_NAVIGATION_ITEMS,
    CONFIG_SIDE_NAVIGATION_ITEMS,
    INavigationItem,
} from '@/definitions';

const state: INavigationState = {
    sideNavigationOpen: false,
    sideNavigationItems: SIDE_NAVIGATION_ITEMS,
    configSideNavigationItems: CONFIG_SIDE_NAVIGATION_ITEMS,
};

const mutations: MutationTree<INavigationState> = {
    [NavigationMutations.SET_SIDE_NAVIGATION_OPEN](state, sideNavigationOpen: boolean) {
        state.sideNavigationOpen = sideNavigationOpen;
    },
};

const actions: ActionTree<INavigationState, any> = {
    [NavigationActions.TOGGLE_SIDE_NAVIGATION]({ commit }) {
        commit(NavigationMutations.SET_SIDE_NAVIGATION_OPEN, !state.sideNavigationOpen);
    },
};

const getters: GetterTree<INavigationState, any> = {
    [NavigationGetters.SIDE_NAVIGATION_OPEN](state): boolean {
        return state.sideNavigationOpen;
    },
    [NavigationGetters.SIDE_NAVIGATION_ITEMS](state): INavigationItem[] {
        return state.sideNavigationItems;
    },
    [NavigationGetters.CONFIG_SIDE_NAVIGATION_ITEMS](state): INavigationItem[] {
        return state.configSideNavigationItems;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
