import Vuex from 'vuex';

import ApiTokenStore from '@/store/modules/apiToken.store';
import AuthStore from '@/store/modules/auth.store';
import FilterStore from '@/store/modules/filter.store';
import NavigationStore from '@/store/modules/navigation.store';
import PermissionStore from '@/store/modules/permission.store';
import RequestStore from '@/store/modules/request.store';
import ScriptStore from '@/store/modules/script.store';
import UiStore from '@/store/modules/ui.store';
import UserStore from '@/store/modules/user.store';
import CompanyStore from '@/store/modules/company.store';
import LevelStore from '@/store/modules/level.store';
import NumberStore from '@/store/modules/number.store';

export default new Vuex.Store({
    modules: {
        apiToken: ApiTokenStore,
        auth: AuthStore,
        filter: FilterStore,
        navigation: NavigationStore,
        permission: PermissionStore,
        requestStore: RequestStore,
        script: ScriptStore,
        ui: UiStore,
        user: UserStore,
        company: CompanyStore,
        level: LevelStore,
        number: NumberStore,
    },
});
