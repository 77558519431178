import { IRole, IDefaultServiceResult } from '@/definitions';
import BaseService from '@/services/BaseService';

class RoleService extends BaseService<IRole> {
    addPermission = (id: number, permissionId: string) => {
        return this.http.post<IDefaultServiceResult>(`${this.route}/${id}/permission`, {
            permissionId,
        });
    };
    removePermission = (id: number, permissionId: string) => {
        return this.http.delete<IDefaultServiceResult>(
            `${this.route}/${id}/permission/${permissionId}`,
        );
    };
}

export default new RoleService('roles');
