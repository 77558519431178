import { IUser, IDefaultServiceResult } from '@/definitions';
import BaseService from '@/services/BaseService';

class UserService extends BaseService<IUser> {
    activate = (id: number | string) => {
        return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/activate`);
    };
    inactivate = (id: number | string) => {
        return this.http.put<IDefaultServiceResult>(`${this.route}/${id}/inactivate`);
    };
}

export default new UserService('users');
